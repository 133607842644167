import * as React from 'react'
import { Box } from '@chakra-ui/react'
import { Outlet, useLocation } from '@remix-run/react'

import { findRoute } from '@/routeUtils/route'

const Pages: React.FC = () => {
  const location = useLocation()
  const route = findRoute(location.pathname)
  const title = route ? `${route.meta.title} - 現場時録` : '現場時録'

  React.useEffect(() => {
    document.title = title
  }, [title])

  return (
    <Box width="100%">
      <Outlet />
    </Box>
  )
}

export default Pages
